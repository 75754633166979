<template>
  <b-card no-body class="border-primary">
    <div v-if="myProduct && mainProduct">
      <b-card-header
        class="d-flex justify-content-between align-items-center pt-75 pb-25"
      >
        <h5 class="mb-0">{{ mainProduct.name }}</h5>
        <b-badge v-if="isInactive" variant="danger">
          {{ $t('admin.plansCard.inactive') }}
        </b-badge>
        <b-badge v-else variant="light-primary"
          >{{ myProduct.metadata.pricingName }}
        </b-badge>
      </b-card-header>

      <b-card-body>
        <ul class="list-unstyled my-1">
          <li>
            <span class="align-middle"
              >{{ myProduct.metadata.allowedMeasurements }}
              {{ $t('admin.plansCard.measurements') }}</span
            >
          </li>
          <li class="my-25">
            <span class="align-middle">{{ myProduct.interval }}</span>
          </li>
          <li v-if="TrialEnd && TrialEnd > Date.now() / 1000">
            <span
              >{{ $t('admin.plansCard.trialEnd') }}:
              {{ fromTimestampToStringDateOnly(TrialEnd) }}
            </span>
          </li>
          <li>
            <span class="align-middle"
              >{{ myProduct.metadata.allowedSellers }}
              {{ $t('admin.plansCard.sellers') }}</span
            >
          </li>
        </ul>
        <b-button
          v-if="!isInactive || !isSuperAdmin"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          to="/upgradeplan"
        >
          {{ $t('admin.plansCard.upgradePlan') }}
        </b-button>
      </b-card-body>
    </div>
    <div v-else-if="plan && plan != 'no-plan'">
      <b-card-header
        class="d-flex justify-content-between align-items-center pt-75 pb-25"
      >
        <h5 class="mb-0">{{ plan.name }}</h5>
        <b-badge v-if="isInactive" variant="danger">{{
          $t('status.inactive')
        }}</b-badge>
        <b-badge v-else variant="light-primary">LICENSE</b-badge>
      </b-card-header>
      <b-card-body>
        <ul class="list-unstyled my-1">
          <li class="my-25">
            <span class="align-middle">Renew every 30 days</span>
          </li>
          <li>
            <span class="align-middle"
              >{{ plan.allowedMeasurements }}
              {{ $t('admin.plansCard.measurements') }}</span
            >
          </li>
          <li>
            <span class="align-middle"
              >{{ plan.allowedSellers }}
              {{ $t('admin.plansCard.sellers') }}</span
            >
          </li>
        </ul>
      </b-card-body>
    </div>
    <div v-else-if="plan == 'no-plan'">
      <b-card-header
        class="d-flex justify-content-between align-items-center pt-75 pb-25"
      >
        <h5 class="mb-0">{{ $t('admin.plansCard.upgradePlan') }}</h5>
        <b-badge v-if="isInactive" variant="danger">{{
          $t('status.inactive')
        }}</b-badge>
        <b-badge v-else variant="light-primary">LICENSE</b-badge>
      </b-card-header>
      <b-card-body>
        <ul class="list-unstyled my-1">
          <li class="my-25">
            <span class="align-middle">Renew every 30 days</span>
          </li>
          <li>
            <span class="align-middle"
              >{{ plan.allowedMeasurements }}
              {{ $t('admin.plansCard.measurements') }}</span
            >
          </li>
          <li>
            <span class="align-middle"
              >{{ plan.allowedSellers }}
              {{ $t('admin.plansCard.sellers') }}</span
            >
          </li>
        </ul>
         <b-button
          v-if="!isInactive || !isSuperAdmin"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          to="/upgradeplan"
        >
          {{ $t('admin.plansCard.upgradePlan') }}
        </b-button>
      </b-card-body>
    </div>
    <div v-else style="margin: 20px">
      <b-skeleton width="80%" />
      <b-skeleton width="50%" />
      <b-skeleton width="100%" />
      <b-skeleton type="button" width="100%" />
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BButton,
  BSkeleton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import RoleEnum from '@/utils/roles'
import Controller from '../../../controllers/base.controller'
import LaboratoryController from '@/controllers/laboratory.controller'
import SoftwarePartnerController from '@/controllers/software-partner.controller'
import OpticController from '@/controllers/optic.controller'

import { fromTimestampToStringDateOnly } from '../../../utils/dates'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BSkeleton,
  },
  data() {
    return {
      plan: null,
      myProduct: null,
      mainProduct: null,
      TrialEnd: null,
      user: null,
      fromTimestampToStringDateOnly,
    }
  },
  async created() {
    if (this.isSuperAdmin) {
      this.user = await this.$store.dispatch(
        'user/fetchOneUser',
        this.$route.params.id,
      )
    } else {
      this.user = this.$store.state.auth.user
    }

    //First attemp get STRIPE subscription
    try {
      const subscriptionSnap = await new Controller().getAll([
        'customers',
        this.user.id,
        'subscriptions',
      ])
      if (!subscriptionSnap.empty) {
        const mySubscription = subscriptionSnap.docs.pop().data()
        const mainProduct = await new Controller().getWithRef(
          mySubscription.product,
        )
        const myProduct = await new Controller().getWithRef(
          mySubscription.prices[0],
        )
        this.myProduct = myProduct.data()
        this.mainProduct = mainProduct.data()
        this.TrialEnd = mySubscription.trial_end.seconds
      }
    } catch (e) {
      console.log(e)
      //throw new Error('no-customer-subscription')
    }

    //Second attemp get STRIPE subscription

    try {
      let adminDocRef = this.user.adminDocRef.split('/')
      let controller = null
      switch (adminDocRef[0]) {
        case 'software-partners':
          controller = new SoftwarePartnerController()
          let sp = await controller.getOne(this.user.adminDocRef)
          this.plan = Object.assign(
            {
              allowedMeasurements: sp.allowedMeasurements,
              allowedSellers: sp.allowedSellers,
              name: 'Software Partner',
            },
            this.plan,
          )
          break
        case 'laboratories':
          controller = new LaboratoryController()
          let lab = await controller.getOne(this.user.adminDocRef)
          this.plan = Object.assign(
            {
              allowedMeasurements: lab.allowedMeasurements,
              allowedSellers: lab.allowedSellers,
              name: 'Laboratory',
            },
            this.plan,
          )
          break
        case 'optics':
          controller = new OpticController()
          let optic = await controller.getOne(this.user.adminDocRef)
          if(this.user.plan.id) {
            this.plan = Object.assign(
              {
                allowedMeasurements: optic.allowedMeasurements,
                allowedSellers: optic.allowedSellers,
                name: 'Optic',
              },
              this.plan,
            )
          } else this.plan = 'no-plan'
          break
        default:
          console.log(`Sorry`)
      }
    } catch (e) {
      console.log(e)
      // throw new Error('no-lensxpert-plan')
    }
  },

  computed: {
    isInactive() {
      return this.$store.getters['auth/inactive']
    },
    isSuperAdmin() {
      return this.$store.state.auth.user.role === RoleEnum.SUPER_ADMIN
        ? true
        : false
    },
  },
}
</script>

<style></style>
