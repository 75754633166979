<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <!-- <b-avatar
            rounded
            class="mr-1"
            size="104px"
            :src="logoUrl"
            :text="avatarText(userData.name)"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          /> -->
          <div class="d-flex flex-column">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.name + ' ' + userData.lastName }}
              </h4>

              <span class="card-text">{{ userData.email }}</span>

              <div>
                <b-badge
                  v-if="$store.getters['auth/inactive']"
                  variant="light-danger"
                >
                  {{ $t('status.inactive') }}
                </b-badge>
              </div>
            </div>

            <div v-if="!isSuperAdmin" class="d-flex flex-wrap">
              <b-row class="mx-0">
                <b-button
                  class="mt-1 mr-1"
                  variant="primary"
                  @click="openEditModal()"
                  >{{ $t('admin.labs.profile.infoCard.edit') }}</b-button
                >
                <b-button
                  @click="resetPassword()"
                  size="sm"
                  class="mt-1"
                  variant="outline-warning"
                  >{{ $t('admin.users.resetPassword') }}</b-button
                >
              </b-row>
            </div>
            <div v-else>
              <b-button
                @click="resetPassword()"
                size="sm"
                class="mt-1"
                variant="outline-warning"
                >{{ $t('admin.users.resetPassword') }}</b-button
              >
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div
          class="d-flex align-items-center mt-2"
          v-if="!isSeller && !isSuperAdmin"
        >
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="ApertureIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ qtyMeasurements }}</h5>
              <small>{{
                $t('admin.labs.profile.infoCard.measurements')
              }}</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ qtySellers }}</h5>
              <small>{{ $t('admin.labs.profile.infoCard.sellers') }}</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="CalendarIcon" class="mr-75" />
              <span class="font-weight-bold">{{
                $t('admin.labs.profile.infoCard.createdOn')
              }}</span>
            </th>

            <td class="pb-50 text-capitalize">
              {{ userData.createdAt | date }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">{{
                $t('admin.labs.profile.infoCard.role')
              }}</span>
            </th>

            <td class="pb-50 text-capitalize">
              {{ userData.role | role }}
            </td>
          </tr>

          <tr v-if="isSeller">
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">{{
                $t('admin.labs.profile.infoCard.hiredBy')
              }}</span>
            </th>

            <td class="pb-50 text-capitalize">
              {{ hiredBy }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">{{
                $t('admin.labs.profile.infoCard.cpf')
              }}</span>
            </th>

            <td class="pb-50 text-capitalize">
              {{ userData.cpf | cpf }}
            </td>
          </tr>

          <tr v-if="!isSuperAdmin">
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">{{
                $t('admin.labs.profile.infoCard.contact')
              }}</span>
            </th>

            <td>
              {{ userData.phoneNumber ? userData.phoneNumber : '' }}

              <b-badge variant="danger" v-if="!userData.phoneNumber">
                <feather-icon icon="AlertTriangleIcon" />
                {{ $t('admin.labs.profile.infoCard.pending') }}
              </b-badge>
            </td>
          </tr>

          <tr v-if="!isSuperAdmin && !isSeller">
            <th class="pb-50">
              <feather-icon icon="MapIcon" class="mr-75" />

              <span class="font-weight-bold">{{
                $t('admin.labs.profile.infoCard.address')
              }}</span>
            </th>

            <td class="pb-50">
              <span
                v-if="
                  userData.address.line1 ||
                  userData.address.line2 ||
                  userData.address.number ||
                  userData.address.zipCode ||
                  userData.address.country
                "
              >
                {{
                  `${userData.address.line1} ${userData.address.line2} ${userData.address.number}  ${userData.address.zipCode} ${userData.address.country}`
                }}
              </span>

              <b-badge variant="danger" v-else>
                <feather-icon icon="AlertTriangleIcon" />
                {{ $t('admin.labs.profile.infoCard.pending') }}
              </b-badge>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BBadge, BButton, BAvatar, BRow, BCol } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

import RoleEnum from '@/utils/roles'
// import { createStorageUrl } from '@/utils/images'
import { cpf } from '@/utils/formatters'

import Controller from '@/controllers/base.controller'
import AuthController from '@/controllers/auth.controller'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ProfileInfoCard',

  components: {
    BCard,
    BBadge,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },

  props: {
    userData: {
      type: Object,
      required: true,
    },
    additionalData: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    parent: null,
  }),

  filters: {
    /**
     * Gets a formatted CPF.
     *
     * @param {string} value the CPF to be formatted.
     */
    cpf(value) {
      return cpf(value.replace(/[^\d]/g, ''))
    },

    /**
     * Gets the user role string label.
     *
     * @param {string} value the user role.
     */
    role(value) {
      return RoleEnum.getRoleAsString(value)
    },

    /**
     * Gets a formatted date.
     *
     * @param {string | Date | number} value the date to be formatted.
     */
    date(value) {
      return new Date(value).toDateString()
    },
  },

  computed: {
    qtyMeasurements() {
      return this.additionalData ? this.additionalData.qtyMeasurements || 0 : 0
    },
    qtySellers() {
      return this.additionalData ? this.additionalData.qtySellers || 0 : 0
    },
    isSuperAdmin() {
      return this.userData.role === RoleEnum.SUPER_ADMIN
    },
    isSeller() {
      return this.userData.role === RoleEnum.SELLER
    },
    // logoUrl() {
    //   if (this.additionalData) return createStorageUrl(this.additionalData.logo)
    //   return ''
    // },
    hiredBy() {
      if (!this.parent) {
        return '-'
      }

      return this.parent.nameFantasy || this.parent.nameRegistered
    },
  },

  methods: {
    avatarText,
    async resetPassword() {
      new AuthController()
        .resetPassword(this.userData.email)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('toast.resetPassword.success'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch((e) => {
          console.error(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('toast.resetPassword.failure'),
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    openEditModal() {
      this.$emit('edit-modal', true)
    },
    resolveUserRoleVariant(role) {
      switch (role) {
        case RoleEnum.SUPER_ADMIN:
          return 'danger'
        case RoleEnum.SOFTWARE_ADMIN:
          return 'warning'
        case RoleEnum.LABORATORY_ADMIN:
          return 'primary'
        case RoleEnum.OPTIC_ADMIN:
          return 'info'
        case RoleEnum.SELLER:
          return 'success'
        default:
          return 'primary'
      }
    },

    /**
     * Loads the seller parent data according to the given path.
     *
     * @param {string} path the path to the parent document.
     */
    async getParentByPath(path) {
      const parts = path.split('/')

      const doc = await new Controller().getById(parts)

      this.parent = doc.data()
    },
  },

  mounted() {
    if (!this.additionalData || !this.isSeller) {
      return
    }

    const sellerRef = this.userData.sellerDocRef
    const parentRef = sellerRef.split('/sellers')[0]

    if (!parentRef || parentRef.includes('sellers')) {
      return
    }

    this.getParentByPath(parentRef)
  },
}
</script>
