<template>
  <div v-if="userData">
    <b-row>
      <b-col>
        <profile-info-card
          :user-data="userData"
          :additional-data="userAdminDocData"
          @edit-modal="editModal = !editModal"
        />
      </b-col>
      <b-col cols="12" md="5" xl="3" lg="4" v-if="isPlanParent">
        <profile-plan-card />
      </b-col>
    </b-row>

    <h2 v-if="adminDocDataSection && userAdminDocData">
      {{ adminDocDataSection }}
    </h2>

    <b-row>
      <OpticForm
        v-if="
          userData && userData.role === RoleEnum.OPTIC_ADMIN && userAdminDocData
        "
        class="mx-1"
        :profile="true"
        :data="userAdminDocData"
      />

      <LabForm
        v-if="
          userData &&
          userData.role === RoleEnum.LABORATORY_ADMIN &&
          userAdminDocData
        "
        class="mx-1"
        :profile="true"
        :data="userAdminDocData"
      />

      <SoftwarePartnerForm
        v-if="
          userData &&
          userData.role === RoleEnum.SOFTWARE_ADMIN &&
          userAdminDocData
        "
        class="mx-1"
        :profile="true"
        :data="userAdminDocData"
      />
    </b-row>

    <b-modal
      v-model="editModal"
      :title="$t('profile.editUserForm.editTitle')"
      hide-footer
      size="lg"
    >
      <profile-edit-user
        :user-data="userData"
        @edit-modal="editModal = !editModal"
      />
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BAlert, BLink } from 'bootstrap-vue'

import ProfileInfoCard from './ProfileInfoCard.vue'
import ProfilePlanCard from './ProfilePlanCard.vue'
import ProfileTimelineCard from './ProfileTimelineCard.vue'
import ProfilePermissionsCard from './ProfilePermissionsCard.vue'
import ProfileEditUser from './ProfileEditUser.vue'

import OpticForm from '../optics/OpticForm.vue'
import LabForm from '../labs/LabForm.vue'
import SoftwarePartnerForm from '../software-partners/SoftwarePartnerForm.vue'

import RoleEnum from '../../../utils/roles'

export default {
  name: 'Profile',

  components: {
    // Bootstrap
    BRow,
    BCol,
    BAlert,
    BLink,

    // Forms
    OpticForm,
    LabForm,
    SoftwarePartnerForm,

    // Local Components
    ProfileInfoCard,
    ProfilePlanCard,
    ProfileTimelineCard,
    ProfilePermissionsCard,
    ProfileEditUser,
  },

  data() {
    return {
      RoleEnum,
      editModal: false,
    }
  },

  computed: {
    userData() {
      return this.$store.state.auth.user
    },
    adminDocDataSection() {
      switch (this.userData.role) {
        case RoleEnum.OPTIC_ADMIN:
          return 'Minha óptica'
        case RoleEnum.LABORATORY_ADMIN:
          return 'Meu laboratório'
        case RoleEnum.SOFTWARE_ADMIN:
          return 'Meu parceiro de software'
        default:
          return null
      }
    },
    userAdminDocData() {
      return this.$store.state.auth.userAdminDoc
    },
    isPlanParent() {
      if (this.userData.adminDocRef) {
        if (this.userData.adminDocRef.split('/').length > 2) return false
        else return true
      } else {
        return false
      }
    },
  },
}
</script>

<style></style>
